<template>
  <div style="max-width: 1024px">
    <b-card
      title="ค้นหาผลรวมยอดเเทงต่อเลข"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row class="mt-1">
            <b-col
              md="6"
            >
              <b-form-group
                label="ประเภทหวย"
                label-for="mc-type"
              >
                <v-select
                  v-model="form.huayId"
                  label="ประเภทหวย"
                  :options="huayType"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
            >
              <b-form-group
                label="ประเภทการเเทง"
                label-for="mc-type"
              >
                <v-select
                  v-model="form.type"
                  label="ประเภทการเเทง"
                  :options="options"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="number"
              >
                <b-form-group
                  label="เลข"
                  label-for="number"
                >
                  <b-form-input
                    id="number"
                    v-model="form.number"
                    placeholder=""
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="roundId"
              >
                <b-form-group
                  label="รอบหวย (Round Id)"
                  label-for="roundId"
                >
                  <b-form-input
                    id="roundId"
                    v-model="form.roundId"
                    placeholder="หากไม่ระบุจะถือว่าเป็นรอบล่าสุด"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="onSubmit"
          >
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card>
      <b-table
        responsive
        bordered
        :fields="columns"
        :items="data"
      >
        <template #cell(type)="v">
          {{ options[v.value - 1] }}
        </template>

        <template #custom-foot>
          <tr v-if="sum > 0">
            <td>รวม</td>
            <td />
            <td>{{ $store.state.app.toNumber(sum) }}</td>
            <td />
          </tr>
          <tr v-else>
            <td colspan="4">
              No data available
            </td>
          </tr>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import { BCard, BFormGroup , BForm , BFormInput, BTable , BRow , BCol , BButton} from 'bootstrap-vue'
import lottoService from '@/service/lottoService'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

const d = new Date()
const d2 = new Date(d.getTime() - 500 * 60 * 60)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BCard,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      huayType: [],
      huayTypeMapper: {},
      rowsOptions: [5, 10, 15, 25, 50, 100, 250, 500, 0],
      filter: '',
      type: '',
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 10,
        descending: true,
      },
      sum: 0,
      columns: [
        {
          label: 'ประเภทการเเทง',
          key: 'type',
          class:'text-nowrap'
        },
        {
          label: 'ตัวเลข',
          key: 'number',
          class:'text-nowrap'
        },
        {
          label: 'ผลรวม',
          key: 'sum',
          class:'text-nowrap',
          formatter: v => this.$store.state.app.toNumber(v),
        },
        {
          label: 'เปอร์เซ็นจ่าย',
          key: 'multiply',
          class: 'text-center text-nowrap',
        },
      ],
      data: [],
      username: '',
      options: [
        'สามตัวบน',
        'สามตัวโต้ด',
        'สามตัวหน้า',
        'สามตัวท้าย',
        'สองตัวบน',
        'สองตัวล่าง',
        'วิ่งบน',
        'วิ่งล่าง',
      ],
      mapValue: {
        สามตัวบน: 1,
        สามตัวโต้ด: 2,
        สามตัวหน้า: 3,
        สามตัวท้าย: 4,
        สองตัวบน: 5,
        สองตัวล่าง: 6,
        วิ่งบน: 7,
        วิ่งล่าง: 8,
        // เลขชุดสี่ตัว: 9
      },
      form: {
        huayId: '',
        type: '',
        number: '',
        roundId: '',
      },
    }
  },
  mounted() {
    lottoService
        .getAuthen('huay/dict')
        .then(res => {
          for (const v of res.data) {
            this.huayType.push(v.name)
            this.huayTypeMapper[v.name] = v.id
          }
          this.form.huayId = this.huayType[0]
        })

    if (this.$route.query.id !== undefined) {
      this.username = this.$route.query.id
    }
  },
  methods: {
    onSubmit() {
      this.onRequest({
        pagination: this.pagination,
      });
    },
    async onRequest(props) {
      let {
        page,
        rowsPerPage,
        rowsNumber,
        sortBy,
        descending,
      } = props.pagination;

      let startRow = (page - 1) * rowsPerPage;
      let fetchCount = rowsPerPage === 0 ? rowsNumber : rowsPerPage;

      this.loading = true;

      const form = {...this.form};
      form.huayId = this.huayTypeMapper[this.form.huayId]
      const res = await lottoService.getAuthen(
          `limit/bet/${form.huayId}?round=${form.roundId}&dec=${descending}&limit=${fetchCount}&page=${startRow}&type=${this.mapValue[form.type]}&number=${form.number}`);

      if (res.status === 200) {
        this.data = res.data.list;
        this.sum = res.data.sum;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.rowsNumber = res.data.count;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.loading = false;
      }
    },
    showUserDetial(username) {
      this.$store.state.userDetialId = username;
      this.$router.push("/userDetial");
    },
  },
}
</script>
